



















































































































import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";

// eslint-disable-next-line @typescript-eslint/no-unused-vars
import moment, { Moment } from "moment";
import axios from "@/libs/axios/axios";
import { AxiosError, AxiosResponse } from "axios";
import { find, findIndex, forEach } from "lodash";
import Calendar from "@/common/components/Calendar/Calendar.vue";
import { State } from "vuex-class";

import { UserState } from "@/common/store/user/types";

import { ReplacementInfoInterface } from "@/modules/director/interfaces/ReplacementInfoInterface";
import { PeriodInterface } from "@/modules/director/interfaces/ReplacementInfoInterface";

interface DayInterface {
  day: Moment;
  dayParts: {
    morning: boolean;
    afternoon: boolean;
  };
}

@Component({
  components: {
    Calendar
  }
})
export default class Replacement extends Vue {
  @State("user") user!: UserState;
  @Prop() id!: number;
  calendarDialogVisible = false;
  activeInfoCardMonth = moment();

  activeInfoCard: ReplacementInfoInterface = {
    replacer: "",
    substituting: "",
    school: "",
    address: "",
    place: "",
    startDate: moment(),
    endDate: moment(),
    periods: [],
    class: "",
    type: "",
    icon: "",
    morningDaypart: true,
    afternoonDaypart: true,
    id: 0
  };

  commentDialogVisible = false;

  async mounted() {
    if (this.user.currentTenant && this.user.currentTenant.id) {
      const response: void | AxiosResponse = await axios
        .get("substitutions/" + this.id, {
          headers: {
            "X-VVTOOL-TENANT": this.user.currentTenant.id
          }
        })
        .catch((error: AxiosError) => {
          console.log(error);
        });

      if (response && response.data) {
        this.activeInfoCard = response.data;
        this.activeInfoCardMonth = this.activeInfoCard.startDate
          ? moment(this.activeInfoCard.startDate)
          : this.activeInfoCard.periods &&
            this.activeInfoCard.periods.length > 0
          ? moment(this.activeInfoCard.periods[0].date)
          : moment();
      }
    }
  }

  get daysWithEvents() {
    if (this.activeInfoCard.periods.length > 0) {
      const confirmedDays: DayInterface[] = [];
      const pendingDays: DayInterface[] = [];

      forEach(this.activeInfoCard.periods, function(period: PeriodInterface) {
        if (
          !period.filled &&
          !period.confirming &&
          (period.pending || period.pending === null)
        ) {
          const foundIndex = findIndex(pendingDays, function(
            pendingDay: DayInterface
          ) {
            return pendingDay.day.isSame(moment(period.date), "day");
          });

          if (foundIndex >= 0) {
            if (period.periodOfDay === 1) {
              pendingDays[foundIndex].dayParts.morning = true;
            } else if (period.periodOfDay === 2) {
              pendingDays[foundIndex].dayParts.afternoon = true;
            }
          } else {
            pendingDays.push({
              day: moment(period.date),
              dayParts: {
                morning: period.periodOfDay === 1,
                afternoon: period.periodOfDay === 2
              }
            });
          }
        } else {
          if (period.confirmed) {
            confirmedDays.push({
              day: moment(period.date),
              dayParts: {
                morning: period.periodOfDay === 1,
                afternoon: period.periodOfDay === 2
              }
            });
          }
        }
      });

      return [
        {
          eventType: "single-event",
          color: "green",
          days: confirmedDays
        },
        {
          eventType: "single-event",
          color: "orange",
          days: pendingDays
        }
      ];
    } else {
      return [];
    }
  }

  previousMonth() {
    this.activeInfoCardMonth = this.activeInfoCardMonth
      .clone()
      .subtract(1, "M");
  }

  nextMonth() {
    this.activeInfoCardMonth = this.activeInfoCardMonth.clone().add(1, "M");
  }

  selectDate(selectedDay: Moment) {
    const foundMorning = find(this.activeInfoCard.periods, function(
      period: PeriodInterface
    ) {
      return (
        moment(period.date).isSame(moment(selectedDay), "day") &&
        period.periodOfDay === 1
      );
    });

    const foundAfternoon = find(this.activeInfoCard.periods, function(
      period: PeriodInterface
    ) {
      return (
        moment(period.date).isSame(moment(selectedDay), "day") &&
        period.periodOfDay === 2
      );
    });

    if (
      (foundMorning && foundMorning.pending) ||
      (foundAfternoon && foundAfternoon.pending)
    ) {
      this.$router.push({
        name: "Requests",
        params: { substitutionIdRequest: this.activeInfoCard.id.toString() }
      });
    }
  }

  activeInfoCardPreviousMonth() {
    this.activeInfoCardMonth = this.activeInfoCardMonth
      .clone()
      .subtract(1, "M");
  }

  activeInfoCardNextMonth() {
    this.activeInfoCardMonth = this.activeInfoCardMonth.clone().add(1, "M");
  }

  handleCommentClose() {
    this.commentDialogVisible = false;
  }

  getMonth(date: Moment) {
    return moment(date).startOf("month");
  }

  formatDate(date: Moment, format: string) {
    return moment(date).format(format);
  }

  capitalize = (s: string) => {
    if (typeof s !== "string") return "";
    return s.charAt(0).toUpperCase() + s.slice(1);
  };
}
